#privacy .container {
    font-family: "Roboto",Helvetica,Arial,sans-sarif;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
#privacy h1, h2, h3 {
    color: #333;
}
#privacy p {
    margin: 10px 0;
    text-align: justify;
}

#anchor{
    text-decoration: none;
    color: black;
}
