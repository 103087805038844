.text{
  color: #2a201c;
  font-size: 40px;
  font-weight: 700;
}

.underline{
  width: 161px;
  height:6px;
  background: #2a201c;
  border-radius: 9px;
}

input::file-selector-button {
  background-image: linear-gradient(
    to right,
    #ff7a18,
    #af002d,
    #319197 100%,
    #319197 200%
  );
  background-position-x: 0%;
  background-size: 200%;
  border: 0;
  border-radius: 8px;
  color: #fff;
  padding: 5px 10px;
  text-shadow: 0 1px 1px #333;
  transition: all 0.25s;
}
input::file-selector-button:hover {
  background-position-x: 50%;
  transform: scale(1.1);
}

.form{
  display: flex;
  flex-direction: row;
}

#show {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}  

 .data {
  flex: 2;
  padding: 16px;
}


.data .define {
  color: grey;
  font-size: 12px;
}


#task .box{
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 16px;
  border-top: solid 0.5px #2a201c;
  /* background: linear-gradient(90deg, rgb(246, 248, 248) 0%, rgb(229, 239, 243) 35%, rgb(212, 243, 238) 100%); */
}

#task .row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

#task .image-container {
  flex: 1;
  display: flex;
  padding: 8px; 
  width: 110px;
  height: 100px;
}
#task .image-container img {
  width: 120px;
  height: auto;
  border-radius: 8px;
}

.button-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
}

#task .btn {
  padding: 4px 8px;
  font-size: 11px;
  cursor: pointer;
}

#task .btn.small {
  padding: 2px 6px;
  font-size: 11px;
}

.footer {
  width: 100%;
  text-align: normal; 
  padding: 0 10px;
}

