#ls{
    display:flex;
    flex-direction: column;
    /* margin: auto; */
    width: 400px;
    background: #fff;
    /* padding-bottom: 30px; */
    position: relative;
    /* right: 20%; */
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    
  }

  #su{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin: auto; */
    width: auto;
    background: #fff;
    /* padding-bottom: 30px; */
    position: relative;
    border: none;
    justify-content: center;
    align-items: baseline;
    row-gap: 0px;
    column-gap: 100px;

  }

  .gs{
    position: relative;
  }
  .header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    /* margin-top: 3px; */
  }
  
  .text{
    color: #2a201c;
    font-size: 40px;
    font-weight: 700;
  }
  
  .underline{
    width: 61px;
    height:6px;
    background: #2a201c;
    border-radius: 9px;
  }
  
  .inputs{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap:25px;
  }
  
  .input{
    display: flex;
    align-items:center;
    margin: auto;
    width:320px;
    height:45px;
    background: #eaeaea;
    border-radius: 6px;
  }
  .input img{
    margin: 0px 10px;
  }
  .input input{
    height: 35px;
    width:300px;
    background: transparent;
    border: none;
    outline:none;
    color:#0f0f0f;
    font-size: 16px;
  }
  
  .submit-container{
    display: flex;
    gap: 15px;
    margin: 10px auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .ssubmit-container{
    display: flex;
    gap: 30px;
    margin: 20px auto;
    justify-content: center;
  }
  
  .submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    color: #fff;
    background: #2a201c;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
    border-radius: 50px;
    border: none;
  }

.submit:hover {background-color: #3e8e41}
.submit:disabled{cursor: auto;background-color: #2a201c;}

.submit:active {
  background-color: #e90a0a;
  transform: translateY(2px);
}

#ls p{
    width:fit-content;
    align-items: center;
    font-weight: bold;
}

#su p{
  width:fit-content;
  align-items: center;
  font-weight: bold;
}

#su .box{
  width:400px;
  position: relative;
  background: linear-gradient(50deg, rgb(123, 159, 228) 0%, rgb(106, 137, 240) 35%, rgb(9, 75, 199) 100%);
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

