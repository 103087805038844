#contact{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    row-gap: 10px;
    column-gap: 90px;
    width: auto;
    justify-content: center;
    align-items: baseline;
    margin-top: -3%;
}

#contact .box{
    width:500px;
    position: relative;
    background: linear-gradient(90deg, rgb(211, 245, 247) 0%, rgb(166, 223, 245) 35%, rgb(195, 247, 238) 100%);
    margin:0 0 30px;  
    padding-top: 10px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
}

#contact .box button{
    background: linear-gradient(90deg, rgb(253, 143, 70) 0%, rgb(72, 69, 230) 35%, rgb(235, 97, 104) 100%);
    color:white;
}
