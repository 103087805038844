.cookie-notification {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.cookie-notification p {
    margin: 0;
    padding: 0 10px;
}

.cookie-notification button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 14px;
}

.cookie-notification button:hover {
    background-color: #0056b3;
}
