#pricing{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    row-gap: 10px;
    column-gap: 70px;
    width: auto;
    justify-content: center;
    align-items: center;
    margin-top: -3%;
}

#pricing .box{
    width:300px;
    position: relative;
    background: linear-gradient(10deg, rgba(11, 86, 90, 0.192) 0%, rgba(90, 98, 189, 0.164) 35%, rgb(4, 136, 114) 100%);
    padding-top: 10px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    /* color: white; */
}

#pricing button{
    width:150px;
    border-radius: 20px;
    height:30px;
    font-size: small;
}